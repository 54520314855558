import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function Omi() {
    //          Images
    const [artimg, setArtimg] = useState([])
    const getStudentData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getallbrand/4`
            );
            if (res.status === 200) {
                setArtimg(res.data.data);
            } else {
                setArtimg([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [link, setLink] = useState({});
    const getLink = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getallbrand/5`
            );
            if (res.status === 200) {
                setLink(res.data.data[0]);
            } else {
                setLink([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getStudentData();
        getLink();
    }, [])

    return (
        <>
            <div className="dempp" style={{ backgroundImage: "url('/assets/img/hero/omi.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '790px' }}>
                {/* <h2 style={{ paddingTop: '18%' }}>Our Annual Show</h2>
                <p>Home / Our Annual Show</p> */}
                <h2 style={{ paddingTop: "170px !important" }}>OMI</h2>
                <p>
                    <Link
                        onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
                        to={"/"}
                        style={{ color: "white" }}
                    >
                        Home{" "}
                    </Link>{" "}
                    / omi
                </p>
            </div>
            <div className="overflow-hidden space bgring" id="about-sec">
                <div className="container space-top">
                    <div className="row align-items-center">
                        <div className="title-area mb-30">
                            <h1 className="sec-title" style={{ textAlign: 'center' }}>Our Annual Show</h1>
                        </div>
                        <div className="col-xl-12">
                            <div className="title-area mb-30">
                                <h3 className="sec-title">An Authentic Belly Dance & Aerial Arts show</h3>
                            </div>
                            <p className="sec-desc mt-n2 mb-30">
                                Immerse yourself in the enchanting world of <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI
                                </span>, a mesmerizing live show directed and choreographed by the talented Nupur Shah. <span
                                    style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> transcends boundaries, blending the
                                soulful rhythms of live Egyptian Darbouka music with the captivating artistry of Belly Dance, creating
                                an authentic and unforgettable experience. At a breathtaking height of 30 feet, <span style={{
                                    color: '#bd934c', fontWeight: '700'
                                }} >OMI</span> introduces the awe-inspiring dimension of Aerial
                                Arts, elevating the spectacle to new heights. Witness the fusion of strength and grace as performers
                                masterfully navigate the Aerial realm, adding a dynamic layer to this extraordinary showcase.
                            </p>
                            <p className="sec-desc mt-n2 mb-30">
                                <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> celebrates the diverse tapestry of Belly Dance, featuring various forms such as Oriental Belly Dance,
                                Tribal Fusion Belly Dance, FCBD, Folklore Belly Dance, and more. <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> is the national platform for every
                                member and student of AerialBelly to showcase their talent in the grandest way. Nupur Shah's artistic
                                direction brings forth the purest essence of these dance forms, creating a rich and vibrant tapestry of
                                movement and expression.
                            </p>
                            <p className="sec-desc mt-n2 mb-30">
                                This captivating journey through Belly Dance and Aerial Arts transcends age; participants range from 6 to 75 years old. <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> is a testament to the universal language of dance, embracing individuals of all ages and backgrounds. Experience the magic, energy, and sheer beauty of <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span>, where every movement tells a story, and the stage becomes a canvas for artistic expression. Let Nupur Shah's visionary choreography transport you to a world where Belly Dance and Aerial Arts converge in perfect harmony.
                            </p>
                            <p className="sec-desc mt-n2 mb-30">
                                This recent grand show by AerialBelly was houseful, leaving everyone mesmerized and spellbound!
                            </p>
                            <p className="sec-desc mt-n2 mb-30">
                                <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> is more than a show; it's a celebration of the purest forms of these art forms, inviting you to be a part of an unforgettable experience that happens every year in India.
                            </p>
                            <p className="sec-desc mt-n2 mb-30">
                                Be a part of <span style={{ color: '#bd934c', fontWeight: '700' }} >OMI</span> and get ready to give your art nationwide recognition.
                            </p>
                        </div>
                    </div>
                    <div className="newsletter-form">
                        {link.name ?
                            <Link to={`${link.name}`} target="blank">
                                <button type="submit" className="th-btn gold-btn-2">Book Now</button>
                            </Link>
                            : ''}
                    </div>
                </div>
                <div className="shape-mockup movingX d-none d-xxl-block" data-top="0%" data-right="0%">
                    <img src="assets/img/shape/shape_1.png" alt="shape" />
                </div>
            </div>


            <div className="video-area-two top-bg-center" data-bg-src="assets/img/bg/demo.jpg">
                <div className="video-wrapper">
                    <div className="video-box1 my-5" data-bg-src="assets/img/normal/demo.jpg">
                        <video controls style={{ height: '760px', width: '850px' }}>
                            <source src="assets/img/hero/omivideo.mp4" type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                        </video>
                    </div>
                    <div className="video-text bgringg">
                        <div className="img-box4" style={{ width: '545px' }}>
                            <div className="img1" style={{ marginTop: '-75px' }}>
                                <img src={
                                    artimg.length > 0
                                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${artimg[12].photo}`
                                        : '/assets/img/hero/omi_img1.jpg'
                                } style={{ borderRadius: '30px' }} alt="OMI" />
                            </div>
                            <div className="img1" style={{ marginTop: '35px' }}>
                                <img src={
                                    artimg.length > 0
                                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${artimg[13].photo}`
                                        : '/assets/img/hero/omi_img2.jpg'
                                } style={{ borderRadius: '30px' }} alt="OMI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
       

                @media (max-width: 768px) {
                    .img-box4 {
                        width: 100% !important;
                        
                    }
                }
            `}</style>

        </>
    )
}
export default Omi;