import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";

function Footer() {
  const [email, setEmail] = useState({
    title: 'email'
  })
  async function exampleFunction() {
    const { value: formValues, dismiss } = await Swal.fire({
      html: `
        <h3>Subscribe Now</h3>
        <input id="username" className="swal2-input" style="margin-top: 15px" type="text" placeholder="Name">
        <input id="swal-input1" className="swal2-input" style="margin-top: 15px" type="email" placeholder="Email">
        
        <div class="phone-container" style="display: flex; align-items: center; margin-top: 15px;">
          <select id="country-code" className="form-select" style="width: 45%; margin-right: 10px;">      
            <option value="+91">+91 (India)</option>
            <option value="+44">+971 (Dubai)</option>
            <option value="+1">+1 (USA, Canada)</option>
            <option value="+44">+44 (UK)</option>
            <option value="+61">+61 (Australia)</option>
            <option value="+81">+81 (Japan)</option>
            <option value="+49">+49 (Germany)</option>
            <option value="+33">+33 (France)</option>
            <option value="+39">+39 (Italy)</option>
            <option value="+86">+86 (China)</option>
            <option value="+7">+7 (Russia)</option>
            <option value="+55">+55 (Brazil)</option>
            <option value="+34">+34 (Spain)</option>
            <option value="+27">+27 (South Africa)</option>
            <option value="+82">+82 (South Korea)</option>
            <option value="+63">+63 (Philippines)</option>
            <option value="+92">+92 (Pakistan)</option>
            <option value="+62">+62 (Indonesia)</option>
            <option value="+234">+234 (Nigeria)</option>
            <option value="+52">+52 (Mexico)</option>
            <option value="+31">+31 (Netherlands)</option>
            <option value="+20">+20 (Egypt)</option>
            <option value="+46">+46 (Sweden)</option>
            <option value="+41">+41 (Switzerland)</option>
            <option value="+48">+48 (Poland)</option>
            <option value="+47">+47 (Norway)</option>
            <option value="+45">+45 (Denmark)</option>
            <option value="+351">+351 (Portugal)</option>
            <option value="+30">+30 (Greece)</option>
            <option value="+353">+353 (Ireland)</option>
            <option value="+36">+36 (Hungary)</option>
            <option value="+90">+90 (Turkey)</option>
            <option value="+64">+64 (New Zealand)</option>
            <!-- You can add more country codes here as needed -->
          </select>
          <input id="swal-input2" className="swal2-input" style="width: 70%;" type="number" placeholder="Phone number">
        </div>
      `,
      focusConfirm: false,
      confirmButtonColor: "#bd934c",
      preConfirm: () => {
        return {
          name: document.getElementById("username").value,
          email: document.getElementById("swal-input1").value,
          phone:
            document.getElementById("country-code").value +
            document.getElementById("swal-input2").value,
        };
      },
    });


    if (dismiss) {
      return;
    } else {
      const data = {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
      };

      try {
        function validateEmail(email) {
          const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return regex.test(email);
        }

        if (!data.email || !data.phone) {
          Swal.fire({
            title: "Please enter all data",
            icon: "warning",
            confirmButtonColor: "#bd934c",
          });
        } else if (!validateEmail(data.email)) {
          Swal.fire({
            title: "Please enter a valid email",
            icon: "warning",
            confirmButtonColor: "#bd934c",
          });
        } else {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/subscribe`,
            data
          );
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Subscribe Successfully",
              timer: 1500,
              confirmButtonColor: "#bd934c",
            });
          } else {
            Swal.fire({
              title: "Subscribe failed",
              icon: "error",
              confirmButtonColor: "#bd934c",
            });
          }
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "warning",
          title: "Server Error",
          confirmButtonText: "OK",
          confirmButtonColor: "#bd934c",
        });
      }
    }
  }
  const handleSub = async () => {
    try {
      console.log(email);

      const res = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/deleteAddToCart/1?title=${email.title}`);
      if (res.status === 202) {
        Swal.fire({
          title: "Submit!",
          text: "This Email send Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <footer
        className="footer-wrapper footer-layout2 bgring3"
        data-bg-src="/assets/img/bg/footer_bg_1.png"
      >
        <div className="container">
          <div className="footer-top">
            <div className="row gx-0 align-items-center">
              <div className="col-xl-auto col-md-6">
                <div className="footer-logo">
                  <Link to={'/'}>
                    <img src="/assets/img/White_logo.png" alt="Aerial Belly" />
                  </Link>
                </div>
              </div>
              <div className="col-xl col-md-6">
                <div className="footer-newsletter">
                  <h3 className="h4 newsletter-title">
                    subscribe now to get updates & news about us...
                  </h3>
                  <div className="newsletter-form">
                    <button type="submit" onClick={exampleFunction} className="th-btn gold-btn-2">Subscribe Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="style1" />
        <div className="widget-area" style={{ paddingTop: "60px" }}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6 col-xl-4">
                <div className="widget footer-widget">
                  <h3 className="widget_title">
                    AERIALBELLY STUDIO , AHMEDABAD
                  </h3>
                  <div className="th-widget-about">
                    <div className="th-social-wrapper">
                      <div className="office-hour">
                        <h4 className="title">opening hour:</h4>
                        <span className="desc">
                          Visiting hours Tuesday to Sunday 04:00 PM - 09:00 PM
                        </span>
                        <br />
                        <br />
                        <span className="desc" style={{fontWeight: '700'}}>
                          Meeting On Prior Appointment Basis
                        </span>
                      </div>
                      <div className="th-social">
                        <h6 className="social-title">Follow Us:</h6>
                        <Link to={"https://www.facebook.com/share/9XT9SNsX2vaAyTLk/?mibextid=LQQJ4d"} target="blank">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                        <Link to={"https://www.instagram.com/aerialbelly?igsh=MnNrYmdjYnpmY2w1"} target="blank">
                          <i className="fab fa-instagram"></i>
                        </Link>
                        <Link to={"https://youtube.com/@nupurshahh?si=77KsUw4I3NB4rya0"} target="blank">
                          <i className="fab fa-youtube"></i>
                        </Link>
                        <Link to={"https://g.co/kgs/5HqUQBb"} target="blank">
                          <i className="fa-brands fa-google"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">Quick Links</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "instant" })
                          }
                          to={"/about"}
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "instant" })
                          }
                          to={"/bellydance"}
                        >
                          Belly Dance Classes
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "instant" })
                          }
                          to={"/aerialbellyart"}
                        >
                          Aerial Arts Classes
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "instant" })
                          }
                          to={"/bellydanceworkshop"}
                        >
                          Belly dance Workshop
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "instant" })
                          }
                          to={"/prerecordingclasses"}
                        >
                          Pre-Recorded Classes
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="widget footer-widget">
                  <h3 className="widget_title">Contact Now</h3>
                  <div className="th-widget-contact">
                    <div className="info-box">
                      <div className="info-box_icon">
                        <a
                          href="https://www.google.com/maps/dir//AerialBelly+Studio,+Westside+building,+Shilp+Zaveri,+518,+Shyamal+Cross+Rd,+behind+Iconic,+Shyamal,+Ahmedabad,+Gujarat+380051/@23.0135995,72.5283253,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e9b60d81c7901:0x1a4eb8ec68071c83!2m2!1d72.5283253!2d23.0135995?entry=ttu"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa-sharp fa-solid fa-location-dot"></i>
                        </a>
                      </div>
                      <p className="info-box_text">
                        {" "}
                        518, Shilp Zaveri, Shyamal Cross Rd, behind Iconic,
                        Swinagar Society, Shyamal, Ahmedabad, Gujarat 380051
                      </p>
                    </div>
                    <div className="info-box">
                      <div className="info-box_icon">
                        <i className="fa-solid fa-phone"></i>
                      </div>
                      <p className="info-box_text">
                        <a
                          href="tel:+(91) 84879 90530"
                          className="info-box_link"
                        >
                          +(91) 84879 90530
                        </a>
                      </p>
                    </div>
                    <div className="info-box">
                      <div className="info-box_icon">
                        <i className="fa-solid fa-envelope"></i>
                      </div>

                      <p className="info-box_text">
                        <a
                          href="mailto:aerialbelly@gmail.com"
                          className="info-box_link"
                        >
                          aerialbelly@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <button className="infoboxs" onClick={handleSub}></button>
                <p className="copyright-text">
                  Copyright <i className="fal fa-copyright"></i> 2023 By{" "}
                  <a href="https://torbitmultisoft.com/">
                    Torbit Multisoft Pvt Ltd
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="footer-copyright-right style2">
                  <ul>
                    <li>
                      <Link onClick={() =>
                        window.scrollTo({ top: 0, behavior: "instant" })
                      } to={'/terms-&-conditions'}>Terms & condition</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="scroll-top style2">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "307.919",
            }}
          ></path>
        </svg>
      </div>
    </>
  );
}

export default Footer;
